import React from "react";
import Header from "../header/header";
import NavBar from "../nav/nav-bar";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Footer from "../footer/footer";
import Service from "../webservice/http";
import { useState } from "react";

function AgentInsight() {
  const [edit, setEdit] = useState({});
  const data = [
    {
      question: "Call Resolution Rate",
      weighting: 0.15,
      score_weighting: 0.2,
      score: 85,
      comments:
        "High call resolution rate but room for improvement in complex cases.",
    },
    {
      question: "Average Handle Time (AHT)",
      weighting: 0.1,
      score_weighting: 0.25,
      score: 5.2,
      comments: "AHT is slightly above target; efforts needed to reduce time.",
    },
    {
      question: "Customer Satisfaction (CSAT)",
      weighting: 0.2,
      score_weighting: 0.3,
      score: 90,
      comments: "Customer satisfaction is high with positive feedback overall.",
    },
    {
      question: "First Response Time (FRT)",
      weighting: 0.15,
      score_weighting: 0.15,
      score: 4.5,
      comments:
        "FRT is within acceptable limits, though quicker responses could enhance customer experience.",
    },
    {
      question: "Quality Assurance (QA) Score",
      weighting: 0.15,
      score_weighting: 0.2,
      score: 88,
      comments:
        "QA scores are strong, reflecting good adherence to quality standards.",
    },
    {
      question: "Absenteeism Rate",
      weighting: 0.05,
      score_weighting: 0.1,
      score: 6,
      comments:
        "Absenteeism rate is manageable but requires monitoring to avoid escalation.",
    },
    {
      question: "Adherence to Schedule",
      weighting: 0.1,
      score_weighting: 0.1,
      score: 92,
      comments: "Excellent adherence to schedule; minimal delays reported.",
    },
    {
      question: "Upselling/Cross-selling Rate",
      weighting: 0.05,
      score_weighting: 0.1,
      score: 12,
      comments:
        "Upselling and cross-selling rates are modest; opportunities for increased training.",
    },
    {
      question: "Average Customer Feedback Score",
      weighting: 0.05,
      score_weighting: 0.1,
      score: 8.5,
      comments:
        "Feedback score is positive, indicating general customer approval.",
    },
    {
      question: "Resolution Time for Escalated Cases",
      weighting: 0.05,
      score_weighting: 0.1,
      score: 7,
      comments:
        "Resolution time for escalated cases is reasonable, but further improvements are possible.",
    },
  ];

  const services = new Service();
  return (
    <div className="dashboard-body">
      <Header />
      <NavBar />
      <NotificationContainer />

      <div>
        <div className="container-fluid">
          <div className="my-call">
            <div className="">
              <div
                className="call-table-s"
                style={{ width: "100%", height: "auto" }}
              >
                <div className="my-calls-column">
                  <div className="calls-top-pannel">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="pannel-heading clearfix">
                          <div className="pannel-heading-icon">
                            <i
                              className="fa fa-snowflake-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="pannel-heading-info">
                            <h3>Agent Individual Scorecard </h3>
                            <p>Details</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div
                  className=" flex px-[200px] py-10"
                  style={{ border: "1px solid lightgray" }}
                >
                  <div
                    className="w-1/2 flex"
                    //
                  >
                    <div className=" flex  flex-col w-1/2 text-end mr-4">
                      <span className=" bg-green-500 text-white mb-2 py-4 px-10 ">
                        Name of Agent
                      </span>
                      <span className=" bg-green-500 text-white mb-2 py-4 px-10 ">
                        Department
                      </span>
                      <span className=" bg-green-500 text-white mb-2 py-4 px-10 ">
                        Account
                      </span>
                      <span className=" bg-green-500 text-white mb-2 py-4 px-10 ">
                        Team Lead
                      </span>
                      <span className=" bg-green-500 text-white mb-2 py-4 px-10 ">
                        Date
                      </span>
                    </div>
                    <div className=" flex  flex-col w-1/2 text-center">
                      <span className=" bg-gray-300 text-black mb-2 py-4  px-16 ">
                        John
                      </span>
                      <span className=" bg-gray-300 text-black mb-2 py-4  px-16 ">
                        Sales
                      </span>
                      <span className=" bg-gray-300 text-black mb-2 py-4  px-16 ">
                        Customer Support
                      </span>
                      <span className=" bg-gray-300 text-black mb-2 py-4  px-16 ">
                        Mark
                      </span>
                      <span className=" bg-gray-300 text-black mb-2 py-4  px-16 ">
                        April 4th,2024
                      </span>
                    </div>
                  </div>
                  <div
                    className=" w-1/2"
                    // style={{ border: "1px solid red" }}
                  >
                    <div
                      className=""
                      //   style={{ paddingBottom: "30px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={services.domain + localStorage.getItem("image")}
                          alt="Admin"
                          class="p-1 bg-primary rounded-full "
                          width="125"
                          height="125"
                        />
                      </div>
                      <span
                        className=" flex justify-center mt-6 text-black text-2xl font-extrabold uppercase"
                        // style={{ margin: "20px" }}
                      >
                        Score
                      </span>
                      <span className=" flex justify-center mt-4">
                        <div
                          className=" h-20 w-[50%] flex justify-center items-center bg-gray-100 text-3xl font-bold"
                          //   style={{ border: "1px solid blue" }}
                        >
                          1.60
                        </div>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="pb-24 mt-6">
                  <div className="overflow-x-auto">
                    <table
                      className="m"
                      style={{ border: "1px solid lightgray", width: "100%" }}
                    >
                      <thead className=" border-b-2 lightgray">
                        <tr>
                          <th className="px-6 py-3 text-left text-xl  text-black font-extrabold uppercase tracking-wider">
                            Metrics
                          </th>
                          <th className="px-6 py-3 text-left text-xl  text-black font-extrabold uppercase tracking-wider">
                            Weighting
                          </th>
                          <th className="px-6 py-3 text-left text-xl  text-black font-extrabold uppercase tracking-wider">
                            Score
                          </th>
                          <th className="px-6 py-3 text-left text-xl  text-black font-extrabold uppercase tracking-wider">
                            Weighted Score
                          </th>
                          <th className="px-6 py-3 text-left text-xl  text-black font-extrabold uppercase tracking-wider">
                            Notes/comments
                          </th>
                        </tr>
                      </thead>
                      <tbody className="agent-scorecard">
                        <tr
                          className="bg-white "
                          style={{ backgroundColor: "white" }}
                        >
                          <td
                            style={{
                              fontWeight: "bold",
                              fontSize: "18px",
                              backgroundColor: "#F5F7F8",
                            }}
                            className="p-4"
                          >
                            Voice
                          </td>
                        </tr>
                        {data.map((e, index) => (
                          <tr
                            key={index}
                            className={`m-4 ${
                              index % 2 === 0 ? " bg-gray-200" : "bg-white"
                            }`}
                          >
                            <td className="px-6 py-4 whitespace-nowrap text-xl text-black">
                              {e?.question}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-xl text-black">
                              {e?.score_weighting}%
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-xl text-black">
                              {e?.score}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-xl text-black">
                              {e?.score_weighting}
                            </td>
                            {!edit[index] ? (
                              <td className="px-6 py-4 whitespace-nowrap text-xl text-black flex">
                                {e?.comments}{" "}
                                <span
                                  className=" ml-8 cursor-pointer"
                                  onClick={() =>
                                    setEdit((prev) => ({
                                      ...prev,
                                      [index]: true,
                                    }))
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-pencil"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                  </svg>
                                </span>
                              </td>
                            ) : (
                              <td className="px-6 py-4 whitespace-nowrap text-xl text-black flex">
                                <input
                                  type="text"
                                  defaultValue={e?.comments}
                                  className=" w-[400px] py-1 rounded-xl px-4 "
                                  style={{ border: "1px solid lightgray" }}
                                  onChange={
                                    (e) => console.log(e.target.value)
                                    // const newComments = [...data];
                                    // newComments[index].comments =
                                    //   e.target.value;
                                  }
                                ></input>
                                {/* {e?.comments}{" "} */}
                                <span className=" ml-8 cursor-pointer">
                                  <button
                                    className="px-4 py-1"
                                    style={{ border: "1px solid lightgray" }}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="px-4 py-1 ml-2"
                                    style={{ border: "1px solid lightgray" }}
                                    onClick={() =>
                                      setEdit((prevEdit) => ({
                                        ...prevEdit,
                                        [index]: false,
                                      }))
                                    }
                                  >
                                    Cancel
                                  </button>
                                </span>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AgentInsight;
