import Header from "./header/header";
import Footer from "./footer/footer";
import NavBar from "./nav/nav-bar";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import WebPull from "./webservice/web-pull";
import { Link } from "react-router-dom";
import Service from "./webservice/http";
import ProgressBar from "./progress-bar";
import fetchProgress from "fetch-progress";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import PulseLoader from "react-spinners/PulseLoader";
import { BiError, BiCommentAdd, BiTaskX } from "react-icons/bi";
import { RiSignalWifiErrorFill, RiDeleteRow } from "react-icons/ri";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Filter from "./filter";
import { FaFilter } from "react-icons/fa";
import Pagination from "./components/pagination";
import Select from "react-select";

const CallList = () => {
  const history = useHistory();

  //const { data: callList, error, isPending } = WebPull('https://fb.dataklout.com/api/call/call_list/')

  const [callList, setCallList] = useState(null);

  // console.log("phew phew ", callList);

  const [error, setError] = useState(null);
  const [isPending, setisPending] = useState(true);
  const [audiotype, setAudiotype] = useState("");
  const [pageno, setpageno] = useState("");
  const [totalpage, setTotalpage] = useState([]);
  const [totalresult, setTotalresult] = useState("");
  const [data, setdata] = useState("");
  // const [hasnext, setHasnext] = useState("")
  const [maxpage, settMaxpage] = useState("");
  const [bulkupload, setBulkupload] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshTime, setRefreshTime] = useState(10000);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  /**
   * Fetch Call List data
   */
  async function fetchCallList() {
    let url = "api/call/call_list/";
    // console.log("inside", currentPage);
    setError(null);
    const queryParams = new URLSearchParams(window.location.search);

    const page = queryParams.get("page");

    // console.log(page); //
    if (page == null) {
      url = url + `?page=${1}`;
    } else {
      url = url + `?page=${page}`;
    }
    services.get(url).then((res) => {
      // console.log("newwww", res);
      setisPending(false);
      if (res == "TypeError: Failed to fetch") {
        setError("Connection Error");
      } else {
        try {
          if (res.code == "token_not_valid") {
            localStorage.clear();
            history.push("/login");
          }
          setCallList(res?.data);
          setTotalpage(res?.pagination?.page_numbers);
          setTotalresult(res?.pagination?.total_results);
          settMaxpage(res?.pagination?.total_pages);
          setdata(res);
          setError(null);
        } catch (e) {
          setError(e);
        }
      }
    });
  }

  // console.log("here is the response", callList);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    history.push(`/call-list?page=${pageNumber}`);
  };
  useEffect(() => {
    fetchCallList();
    fetchSupportingInfo();
  }, [history, currentPage]);

  /**
   * Handle refresh call list in each specific interval
   *
   * This is required to display audio processing progress
   */

  useEffect(() => {
    const interval = setInterval(() => {
      fetchCallList();
    }, refreshTime);

    return () => clearInterval(interval);
  }, []);

  const [showCallModal, setShowCallModal] = useState(false);
  const toggolCallModal = () => {
    setShowCallModal(!showCallModal);
  };

  const [supportingInfo, setSupportingInfo] = useState();
  const [supportingInfoPending, setSupportingInfoPending] = useState();
  const [supportingInfoError, setSupportingInfoError] = useState();

  const services = new Service();

  const [uploadFile, setUploadFile] = useState(null);
  const [callType, setCallType] = useState("");
  const [product, setProduct] = useState("");
  const [languageCode, setLanguageCode] = useState("");
  const [customerID, setCustomerID] = useState("");

  //const [uploadData, setUploadData] = useState(null);
  const [uploadPending, setUploadPending] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [callUploadProgress, setCallUploadProgress] = useState(0);

  const [callUploadSucess, setCallUploadSucess] = useState(false);

  // console.log("hello", callUploadProgress);
  /**
   * Upload call recording file with other required details to process
   *
   * axios is being used here to display upload progress
   */

  const uploadCallRecording = () => {
    setCallUploadProgress(0);
    setUploadError(null);
    setSupportingInfoError("");

    if (callType === "") {
      setSupportingInfoError("Please Select Call Type");
      return;
    }

    if (product === "") {
      setSupportingInfoError("Please Select one product");
      return;
    }

    if (languageCode === "") {
      setSupportingInfoError("Please Select one language");
      return;
    }

    if (customerID === "") {
      setSupportingInfoError("Please Select one customer");
      return;
    }

    if (uploadFile === null) {
      setSupportingInfoError("Please select one audio recording file");
      return;
    }
    if (audiotype !== "wav") {
      setSupportingInfoError("Please check the call recording format i.e wav");
      return;
    }

    setUploadPending(true);
    var url = services.domain + "/api/call/new_call/";

    let formData = new FormData();
    formData.append("file", uploadFile);
    formData.append("call_type", callType);
    formData.append("product", product);
    formData.append("language_code", languageCode);
    formData.append("customer_id", customerID);
    // console.log("File size :" + uploadFile.size);

    axios
      .request({
        method: "post",
        url: url,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        data: formData,
        onUploadProgress: (p) => {
          setCallUploadProgress(Math.round((p.loaded * 100) / uploadFile.size));
        },
      })
      .then((data) => {
        setUploadPending(false);
        setUploadError(null);
        console.log(data.data.message);
        if (data.data.message === "success") {
          setUploadFile(null);
          setCallType("");
          setProduct("");
          setLanguageCode("");
          setCustomerID();
          setShowCallModal(false);
          fetchCallList();
          NotificationManager.success(
            "Success",
            "Your call uploaded successfully "
          );
          setCallUploadSucess(true);
          setCurrentPage(1);
          history.push(`/call-list?page=${1}`);
        } else {
          setUploadPending(false);
          setUploadError(data.message);
        }
      });
  };

  /**
   * Fetch new call supporting information option data which are required to process a call
   */
  function fetchSupportingInfo() {
    services.get("api/call/new_call/").then((res) => {
      // console.log(res);
      setSupportingInfoPending(false);
      if (res == "TypeError: Failed to fetch") {
        setSupportingInfoError("Failed to Fetch");
      } else {
        if (res.code == "token_not_valid") {
          localStorage.clear();
          history.push("/login");
        }

        setSupportingInfo(res);
        setSupportingInfoError(null);
      }
    });
  }

  // console.log(audiotype);

  /**
   * Navigate to call insight
   * @param {*} callId Call ID
   * @param {*} intent Intent Value
   */
  function openCallInsight(callId, intent) {
    if (intent !== 0) {
      history.push(`/call/${callId}/call-insight`);
    }
  }

  function openComplainstInsight(callId, intent) {
    if (intent !== 0) {
      history.push(`/ticket/${callId}/complaints-insight`);
    }
  }

  /**
   * Remove processing failed calls
   * @param {*} callId Failed call ID
   */
  function removeFailedItem(callId) {
    services.post(`api/call/${callId}/mark_failed/`).then((res) => {
      if (res == "TypeError: Failed to fetch") {
        NotificationManager.error("Error", "Connection Error");
      } else {
        if (res.code == "token_not_valid") {
          localStorage.clear();
          history.push("/login");
        }
        if (res.message === "success") {
          NotificationManager.success("Success", "Removed item");
          fetchCallList();
        }
      }
    });
  }

  const [sideBarWidth, setSideBarWidth] = useState(0);
  const usecase = localStorage.getItem("usecase");
  // console.log(usecase);
  /**\
   * Change side filter bar width
   */
  const changeSideBarWidth = () => {
    if (sideBarWidth == 570) {
      setSideBarWidth(0);
      setIsFilterOpen(false);
    } else {
      setSideBarWidth(570);
      setIsFilterOpen(true);
    }
  };

  const filterHasChanged = () => {
    fetchCallList();
  };

  const page = [1, 2, 3, 4, 5];

  const [xlsxfile, setxlsxFile] = useState(null);
  // const handleFileChange = (event) => {
  //   setxlsxFile(event.target.files[0]);
  // };

  // const handleUpload = () => {
  //   var url = "https://fb.dataklout.com/api/call/bulk_call/";
  //   let formData = new FormData();
  //   formData.append("file", xlsxfile);
  //   axios
  //     .request({
  //       method: "post",
  //       url: url,
  //       headers: {
  //         Authorization: "Bearer " + localStorage.getItem("access_token"),
  //       },
  //       data: formData,
  //     })
  //     .then((data) => {
  //       setUploadPending(false);
  //       setUploadError(null);
  //       setShowCallModal(false);

  //       // console.log(data.data.message);
  //       if (data.data.message === "success") {
  //         NotificationManager.success(
  //           "Success",
  //           "Your call uploaded successfully"
  //         );
  //         setCallUploadSucess(true);
  //       } else {
  //         setUploadPending(false);
  //         setUploadError(data.message);
  //       }
  //     });
  // };

  // const handleDownload = () => {
  //   const sampleFilePath = process.env.PUBLIC_URL + "/assets/Sample file.xlsx";
  //   const link = document.createElement("a");
  //   link.setAttribute("href", sampleFilePath);
  //   link.setAttribute("download", "Sample file.xlsx");
  //   document.body.appendChild(link);
  //   link.click();
  // };

  useEffect(() => {
    if (uploadFile) {
      setAudiotype(uploadFile.name.split(".").pop());
    }
  }, [uploadFile]);

  // console.log("nono", audiotype);

  // Bulk upload

  const [bulkdata, setBulkdata] = useState({
    call_type: "",
    product: "",
    language_code: "",
    customer_id: "",
    files: null,
  });

  const [addBulkCall, setAddBulkCall] = useState([]);
  const [callName, setCallName] = useState([]);
  const [emptycall, setEmptycall] = useState(false);
  const [fileInputKey, setFileInputKey] = useState(0);
  const [delcall, setDelcall] = useState("");

  const handelBulkChange = function (key, value) {
    setEmptycall(false);
    setSupportingInfoError("");
    setBulkdata({ ...bulkdata, [key]: value });
  };

  // console.log("file format ", bulkdata.files);
  // console.log(callName);

  const addCall = function (e) {
    e.preventDefault();
    if (bulkdata?.call_type === "") {
      setSupportingInfoError("Please Select Call Type");
      return;
    }

    if (bulkdata?.product === "") {
      setSupportingInfoError("Please Select one product");
      return;
    }

    if (bulkdata?.language_code === "") {
      setSupportingInfoError("Please Select one language");
      return;
    }

    if (bulkdata?.customer_id === "") {
      setSupportingInfoError("Please Select one customer");
      return;
    }

    if (bulkdata?.files === null) {
      setSupportingInfoError("Please select one audio recording file");
      return;
    }
    if (bulkdata?.files.name.split(".").pop() !== "wav") {
      setSupportingInfoError("Please check the call recording format i.e wav");
      return;
    }

    // console.log(bulkdata);
    // the if block here can be eliminated but i m just being double sure about the call data by putting a checker again  setting the call error info

    if (
      bulkdata.call_type &&
      bulkdata.product &&
      bulkdata.language_code &&
      bulkdata.customer_id &&
      bulkdata.files
    ) {
      setAddBulkCall((prev) => [...prev, bulkdata]);
      setCallName((prev) => [...prev, bulkdata.files]);
      setBulkdata({
        call_type: "",
        product: "",
        language_code: "",
        customer_id: "",
        files: null,
      });

      setFileInputKey((prevKey) => prevKey + 1);
    } else {
      setEmptycall(true);
    }
  };

  console.log("yyyyyyyyyyy", addBulkCall);

  const [totalDuration, setTotalDuration] = useState(0);

  useEffect(() => {
    const calculateTotalDuration = () => {
      const durations = [];
      let filesCount = 0;

      // Flatten all files from all objects
      const allFiles = addBulkCall.flatMap((dataObject) => dataObject.files);

      allFiles.forEach((file) => {
        const audio = new Audio(URL.createObjectURL(file));

        audio.addEventListener("loadedmetadata", () => {
          durations.push(audio.duration);

          // Check if all files have been processed
          if (durations.length === allFiles.length) {
            // Sum durations
            const total = durations.reduce(
              (sum, duration) => sum + duration,
              0
            );
            setTotalDuration(total);
          }
        });

        audio.addEventListener("error", () => {
          durations.push(0);

          if (durations.length === allFiles.length) {
            const total = durations.reduce(
              (sum, duration) => sum + duration,
              0
            );
            setTotalDuration(total);
          }
        });
      });
    };

    calculateTotalDuration();
  }, [addBulkCall]);

  console.log("ddddddddddd", totalDuration);

  const [durationerror, setDurationerror] = useState(false);

  const handleUpload = () => {
    if (totalDuration < 500) {
      var url = "https://fb.dataklout.com/api/call/tbulk_upload/";
      let formData = new FormData();

      addBulkCall.forEach((call, index) => {
        // console.log(call);
        // console.log(Object.keys(call));
        Object.keys(call).forEach((key) => {
          if (key === "files" && call[key] instanceof File) {
            formData.append(`${key}`, call[key]);
          } else {
            formData.append(`${key}`, call[key]);
          }
        });
      });

      axios
        .request({
          method: "post",
          url: url,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        })
        .then((response) => {
          setUploadPending(false);
          setUploadError(null);
          setShowCallModal(false);

          if (response.data.message === "success") {
            NotificationManager.success(
              "Success",
              "Your call uploaded successfully"
            );
            // setCallUploadSuccess(true);
            setCallUploadSucess(true);
            history.push(`/call-list?page=${1}`);
            setAddBulkCall([]);
            setCallName([]);
          } else {
            setUploadPending(false);
            setUploadError(response.data.message);
          }
        })
        .catch((error) => {
          console.error("Error uploading call:", error);
          setUploadPending(false);
          setUploadError("An error occurred while uploading the call.");
        });
    } else {
      return setDurationerror(true);
    }
    // console.log(addBulkCall);
  };

  // Function to handle deletion of a call
  const handleDeleteCall = (index) => {
    setAddBulkCall((prevCalls) => prevCalls.filter((_, i) => i !== index));
  };
  // console.log("customer id", supportingInfo.customer);

  useEffect(() => {
    fetchCallList();
  }, [callUploadSucess]);

  useEffect(() => {
    if (isFilterOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isFilterOpen]);

  console.log(supportingInfo);

  return (
    <div className="dashboard-body">
      <Header />
      <NavBar />
      {localStorage.getItem("usecase") !== "Third Party Collection Agency" ? (
        <a
          onClick={changeSideBarWidth}
          style={{
            position: "fixed",
            transition: ".5s",
            top: "30%",
            right: sideBarWidth,
            backgroundColor: "#F99E52",
            color: "white",
            borderTopColor: "black",
            borderWidth: "2px",
            boxShadow: "6px 6px 3px #999",
            paddingTop: "15px",
            paddingLeft: "5px",
            paddingRight: "5px",
            height: "45px",
            zIndex: "2",
          }}
        >
          <FaFilter size="20" />
        </a>
      ) : (
        ""
      )}

      <Filter
        width={sideBarWidth}
        changeSideBarWidth={changeSideBarWidth}
        filterType="call-list"
        filterHasChanged={filterHasChanged}
      />
      {sideBarWidth == 570 && (
        <div className="backdrop" onClick={changeSideBarWidth}></div>
      )}

      <NotificationContainer />
      <div className="container-fluid">
        <div className="my-call">
          <div className="my-calls-column">
            <div className="calls-top-pannel">
              <div className="row">
                <div className="col-lg-6">
                  <div className="pannel-heading clearfix">
                    <div className="pannel-heading-icon">
                      <i className="flaticon-incoming-call"></i>
                    </div>
                    <div className="pannel-heading-info">
                      {localStorage.getItem("usecase") ===
                      "Complaints Management" ? (
                        <p>Tickets</p>
                      ) : (
                        <p>Calls </p>
                      )}
                      {localStorage.getItem("usecase") ===
                      "Complaints Management" ? (
                        <h3>My Tickets</h3>
                      ) : localStorage.getItem("usecase") ===
                        "Third Party Collection Agency" ? (
                        <h3>My Calls : Collection </h3>
                      ) : (
                        <h3>My Calls</h3>
                      )}
                    </div>
                  </div>
                  <div className="bradcums">
                    <ul className="clearfix">
                      <li>
                        {" "}
                        <i className="fa fa-circle" aria-hidden="true"></i>{" "}
                        {callList && callList.length} Items
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="pannel-nav clearfix">
                    <ul className="clearfix">
                      <li onClick={toggolCallModal}>
                        {localStorage.getItem("usecase") ===
                        "Complaints Management" ? (
                          <a>Upload</a>
                        ) : (
                          <a>New </a>
                        )}
                      </li>

                      <li
                        onClick={() => {
                          if (
                            localStorage.getItem("usecase") ===
                              "Third Party Collection Agency" ||
                            localStorage.getItem("usecase") ===
                              "Augmenting Digital Reach"
                          ) {
                            return;
                          } else {
                            history.push("/live-call");
                          }
                        }}
                        className={
                          localStorage.getItem("usecase") ===
                            "Third Party Collection Agency" ||
                          localStorage.getItem("usecase") ===
                            "Augmenting Digital Reach"
                            ? " cursor-not-allowed opacity-50"
                            : ""
                        }
                      >
                        {localStorage.getItem("usecase") ===
                        "Complaints Management" ? (
                          <a>Live Tickets</a>
                        ) : (
                          <a>Live Call </a>
                        )}
                      </li>
                    </ul>
                  </div>
                  <div className="clear"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="call-table">
            {isPending ? (
              <div className=" flex justify-center">
                <ClipLoader color="#2056FF" size="50px" />
              </div>
            ) : (
              <table className="ss">
                <thead>
                  {localStorage.getItem("usecase") ===
                  "Complaints Management" ? (
                    <tr className="complaint-mang">
                      <th>
                        <p> Date</p>
                      </th>
                      <th>
                        <p>Ticket Number </p>
                      </th>
                      <th>
                        <p>Language</p>
                      </th>
                      <th>
                        <p>Product</p>
                      </th>
                      <th>
                        <p>Services</p>
                      </th>
                      <th>
                        <p>Sentiments</p>
                      </th>
                      <th>
                        <p>Intent</p>
                      </th>

                      <th>
                        <p>Status</p>
                      </th>
                    </tr>
                  ) : localStorage.getItem("usecase") ==
                    "Third Party Collection Agency" ? (
                    <tr>
                      <th>
                        <p>Date</p>
                      </th>
                      <th>
                        <p>Customer</p>
                      </th>
                      <th>
                        <p>Call ID</p>
                      </th>
                      <th>
                        <p>Agent</p>
                      </th>
                      <th>
                        <p>Language</p>
                      </th>
                      <th>
                        <p>Product</p>
                      </th>
                      <th>
                        <p> Sentiments</p>
                      </th>

                      <th>
                        <p>Intent</p>
                      </th>
                      <th className=" whitespace-nowrap">
                        <p>Agent Recommendation</p>
                      </th>
                      <th className=" whitespace-nowrap">
                        <p>Dataklout Recommendation</p>
                      </th>
                    </tr>
                  ) : localStorage.getItem("usecase") ==
                    "Insurance - PCVC Verification" ? (
                    <tr>
                      <th>
                        <p>Call Date</p>
                      </th>
                      <th>
                        <p>NEMP Id</p>
                      </th>
                      <th>
                        <p>Customer</p>
                      </th>
                      <th>
                        <p>Language</p>
                      </th>

                      <th>
                        <p>Product Type</p>
                      </th>

                      <th>
                        <p>Product</p>
                      </th>

                      <th>
                        <p>Status</p>
                      </th>
                    </tr>
                  ) : localStorage.getItem("usecase") == "Banking" ? (
                    <tr>
                      <th>
                        <p>Call Date</p>
                      </th>
                      <th>
                        <p>Customer</p>
                      </th>
                      <th>
                        <p>Agent</p>
                      </th>
                      <th className=" whitespace-nowrap">
                        <p>Call ID</p>
                      </th>
                      <th>
                        <p>Language</p>
                      </th>
                      <th>
                        <p>Product</p>
                      </th>
                      <th>
                        <p>Call Type</p>
                      </th>

                      <th>
                        <p>Sentiments</p>
                      </th>
                      <th>
                        <p>Intent</p>
                      </th>
                    </tr>
                  ) : (
                    <tr>
                      <th>
                        <p>Call Date</p>
                      </th>
                      <th>
                        <p>Customer</p>
                      </th>
                      <th>
                        <p>Agent</p>
                      </th>
                      <th>
                        <p>Language</p>
                      </th>
                      <th>
                        <p>Product</p>
                      </th>
                      <th>
                        <p>Call Type</p>
                      </th>
                      {localStorage.getItem("collection_module") === "true" && (
                        <>
                          <th className=" whitespace-nowrap">
                            <p>Agent Recommendation</p>
                          </th>
                          <th className=" whitespace-nowrap">
                            <p>Dataklout Recommendation</p>
                          </th>
                        </>
                      )}
                      <th>
                        <p>Sentiments</p>
                      </th>
                      <th>
                        <p>Intent</p>
                      </th>
                    </tr>
                  )}
                </thead>
                {isPending && (
                  <tr
                    style={{
                      height: "400px",
                      textAlignVertical: "center",
                      textAlign: "center",
                    }}
                  >
                    <td colSpan="6">
                      <ClipLoader color="#2056FF" size="50px" />
                    </td>
                  </tr>
                )}

                {error && (
                  <tr
                    style={{ textAlignVertical: "center", textAlign: "center" }}
                  >
                    <td colSpan="6">
                      <p style={{ fontSize: "25px", color: "#FF8520" }}>
                        {error === "Connection Error" && (
                          <RiSignalWifiErrorFill />
                        )}
                        {error !== "Connection Error" && <BiError />}
                        {error}
                      </p>
                    </td>
                  </tr>
                )}
                <tbody className="complaint-mang">
                  {callList &&
                    callList?.map((call) =>
                      localStorage.getItem("usecase") ===
                      "Complaints Management" ? (
                        <tr
                          key={call._id}
                          onClick={() =>
                            openComplainstInsight(call._id, call._intent)
                          }
                          className=" cus"
                        >
                          <td style={{ width: "10%" }}>{call?._date}</td>
                          <td style={{ width: "10%" }}>
                            {call?._ticket_number}
                          </td>
                          <td style={{ width: "10%" }}>
                            {call._language.includes("English")
                              ? "English"
                              : call._language}
                          </td>
                          <td style={{ width: "10%" }}>{call._product_name}</td>
                          <td style={{ width: "10%" }}>{call._call_type}</td>
                          <td style={{ width: "10%" }}>30%</td>
                          <td style={{ width: "10%" }}>50%</td>
                          <td style={{ width: "10%" }}>Closed</td>
                          {call._intent === 0 && (
                            <>
                              {localStorage.getItem("collection_module") ===
                                "true" && (
                                <td
                                  colSpan="4"
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  title={call._processing_status}
                                >
                                  {call._processing_status !== "Failed" && (
                                    <>
                                      <div className="col-md-10">
                                        <ProgressBar
                                          bgcolor="#271078"
                                          progress={call._progress}
                                          height={20}
                                        />
                                      </div>
                                      <div className="col-md-2">
                                        <PulseLoader
                                          color="#2056FF"
                                          size="10px"
                                        />
                                      </div>
                                    </>
                                  )}
                                  {call._processing_status === "Failed" && (
                                    <>
                                      <BiError size="20px" color="#FF0800" />{" "}
                                      Call Processing Failed, Need Action
                                      <a
                                        onClick={() =>
                                          removeFailedItem(call._id)
                                        }
                                        className="pull-right"
                                        style={{ color: "red" }}
                                      >
                                        <RiDeleteRow size="20px" /> Remove{" "}
                                      </a>
                                    </>
                                  )}
                                </td>
                              )}

                              {localStorage.getItem("collection_module") !==
                                "true" && (
                                <td
                                  colSpan="2"
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  title={call._processing_status}
                                >
                                  {call._processing_status !== "Failed" && (
                                    <>
                                      <div className="col-md-10">
                                        <ProgressBar
                                          bgcolor="#271078"
                                          progress={call._progress}
                                          // progress={progess}
                                          height={20}
                                        />
                                      </div>
                                      <div className="col-md-2">
                                        <PulseLoader
                                          color="#2056FF"
                                          size="10px"
                                        />
                                      </div>
                                    </>
                                  )}
                                  {call._processing_status === "Failed" && (
                                    <>
                                      <BiError size="20px" color="#FF0800" />{" "}
                                      Call Processing Failed, Need Action
                                      <a
                                        onClick={() =>
                                          removeFailedItem(call._id)
                                        }
                                        className="pull-right"
                                        style={{ color: "red" }}
                                      >
                                        <RiDeleteRow size="20px" /> Remove{" "}
                                      </a>
                                    </>
                                  )}
                                </td>
                              )}
                            </>
                          )}
                        </tr>
                      ) : localStorage.getItem("usecase") ===
                        "Third Party Collection Agency" ? (
                        <tr
                          key={call._id}
                          onClick={() =>
                            openCallInsight(call._id, call._intent)
                          }
                        >
                          <td>{call._date}</td>
                          <td>{call?._customer}</td>
                          {/* <td>{call?._nemp_id || "14CH452"}</td> */}
                          <td>{(call?._id).slice(0, 5).toUpperCase()}</td>
                          <td>{call?._agent}</td>
                          <td>{call?._language}</td>

                          <td>
                            {call?._product_name
                              .toLowerCase()
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                          </td>
                          <td>
                            {Math.abs(Math.trunc(call?._sentiment * 100))}%
                          </td>
                          <td>{Math.abs(Math.trunc(call?._intent * 100))}%</td>
                          {localStorage.getItem("collection_module") ===
                            "true" && (
                            <>
                              {call._intent !== 0 && (
                                <>
                                  {call._call_type === "Collection" ? (
                                    <>
                                      <td>
                                        <p>{call._agent_collection_view}</p>
                                      </td>
                                      <td>
                                        <p>{call._dataklout_collection_view}</p>
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td>
                                        <p>NA</p>
                                      </td>
                                      <td>
                                        <p>NA</p>
                                      </td>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                          {call._intent === 0 && (
                            <>
                              {localStorage.getItem("collection_module") ===
                                "true" && (
                                <td
                                  colSpan="4"
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  title={call._processing_status}
                                >
                                  {call._processing_status !== "Failed" && (
                                    <>
                                      <div className="col-md-10">
                                        <ProgressBar
                                          bgcolor="#271078"
                                          progress={call._progress}
                                          // progress={progess}
                                          height={20}
                                        />
                                      </div>
                                      <div className="col-md-2">
                                        <PulseLoader
                                          color="#2056FF"
                                          size="10px"
                                        />
                                      </div>
                                    </>
                                  )}
                                  {call._processing_status === "Failed" && (
                                    <>
                                      <BiError size="20px" color="#FF0800" />{" "}
                                      Call Processing Failed, Need Action
                                      <a
                                        onClick={() =>
                                          removeFailedItem(call._id)
                                        }
                                        className="pull-right"
                                        style={{ color: "red" }}
                                      >
                                        <RiDeleteRow size="20px" /> Remove{" "}
                                      </a>
                                    </>
                                  )}
                                </td>
                              )}

                              {localStorage.getItem("collection_module") !==
                                "true" && (
                                <td
                                  colSpan="2"
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  title={call._processing_status}
                                >
                                  {call._processing_status !== "Failed" && (
                                    <>
                                      <div className="col-md-10">
                                        <ProgressBar
                                          bgcolor="#271078"
                                          progress={call._progress}
                                          // progress={progess}
                                          height={20}
                                        />
                                      </div>
                                      <div className="col-md-2">
                                        <PulseLoader
                                          color="#2056FF"
                                          size="10px"
                                        />
                                      </div>
                                    </>
                                  )}
                                  {call._processing_status === "Failed" && (
                                    <>
                                      <BiError size="20px" color="#FF0800" />{" "}
                                      Call Processing Failed, Need Action
                                      <a
                                        onClick={() =>
                                          removeFailedItem(call._id)
                                        }
                                        className="pull-right"
                                        style={{ color: "red" }}
                                      >
                                        <RiDeleteRow size="20px" /> Remove{" "}
                                      </a>
                                    </>
                                  )}
                                </td>
                              )}
                            </>
                          )}
                        </tr>
                      ) : localStorage.getItem("usecase") ==
                        "Insurance - PCVC Verification" ? (
                        <tr
                          key={call._id}
                          onClick={() =>
                            openCallInsight(call._id, call._intent)
                          }
                        >
                          <td>{call._date}</td>
                          <td>{call?._nemp_id}</td>
                          <td>{call?._customer}</td>
                          <td>{call?._language}</td>
                          <td>
                            {call?._category
                              .toLowerCase()
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                          </td>
                          <td>
                            {call?._product_name
                              .toLowerCase()
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                          </td>

                          {call?._intent !== 0 ? (
                            <td>
                              {call?._call_status ? (
                                <p
                                  className={`whitespace-nowrap ${
                                    call?._call_status === "non-fatal"
                                      ? "text-green-700"
                                      : "text-red-700"
                                  }`}
                                >
                                  {call?._call_status
                                    .toLowerCase()
                                    .replace(/\b\w/g, function (a) {
                                      return a.toUpperCase();
                                    })}
                                </p>
                              ) : (
                                ""
                              )}
                            </td>
                          ) : (
                            ""
                          )}

                          {call._intent === 0 && (
                            <>
                              {localStorage.getItem("collection_module") ===
                                "true" && (
                                <td
                                  colSpan="4"
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  title={call._processing_status}
                                >
                                  {call._processing_status !== "Failed" && (
                                    <>
                                      <div className="col-md-10">
                                        <ProgressBar
                                          bgcolor="#271078"
                                          // progress={call?._progress}
                                          progress={call._progress}
                                          height={20}
                                        />
                                      </div>
                                      <div className="col-md-2">
                                        <PulseLoader
                                          color="#2056FF"
                                          size="10px"
                                        />
                                      </div>
                                    </>
                                  )}
                                  {call._processing_status === "Failed" && (
                                    <>
                                      <BiError size="20px" color="#FF0800" />{" "}
                                      Call Processing Failed, Need Action
                                      <a
                                        onClick={() =>
                                          removeFailedItem(call._id)
                                        }
                                        className="pull-right"
                                        style={{ color: "red" }}
                                      >
                                        <RiDeleteRow size="20px" /> Remove{" "}
                                      </a>
                                    </>
                                  )}
                                </td>
                              )}

                              {localStorage.getItem("collection_module") !==
                                "true" && (
                                <td
                                  colSpan="2"
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  title={call._processing_status}
                                >
                                  {call._processing_status !== "Failed" && (
                                    <>
                                      <div className="col-md-10">
                                        <ProgressBar
                                          bgcolor="#271078"
                                          progress={call._progress}
                                          // progress={progess}
                                          height={20}
                                        />
                                      </div>
                                      <div className="col-md-2">
                                        <PulseLoader
                                          color="#2056FF"
                                          size="10px"
                                        />
                                      </div>
                                    </>
                                  )}
                                  {call._processing_status === "Failed" && (
                                    <>
                                      <BiError size="20px" color="#FF0800" />{" "}
                                      Call Processing Failed, Need Action
                                      <a
                                        onClick={() =>
                                          removeFailedItem(call._id)
                                        }
                                        className="pull-right"
                                        style={{ color: "red" }}
                                      >
                                        <RiDeleteRow size="20px" /> Remove{" "}
                                      </a>
                                    </>
                                  )}
                                </td>
                              )}
                            </>
                          )}
                        </tr>
                      ) : localStorage.getItem("usecase") === "Banking" ? (
                        <tr
                          key={call._id}
                          onClick={() =>
                            openCallInsight(call._id, call._intent)
                          }
                        >
                          <td>{call._date}</td>
                          <td>{call?._customer}</td>
                          <td>{call?._agent}</td>
                          <td>{(call?._id).slice(0, 5).toUpperCase()}</td>
                          <td>
                            {call._language.includes("English")
                              ? "English"
                              : call._language}
                          </td>
                          {/* <td>{call?._nemp_id || "14CH452"}</td> */}

                          <td>
                            {call?._product_name
                              .toLowerCase()
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                          </td>
                          <td>{call._call_type}</td>
                          {call._intent !== 0 && (
                            <>
                              <td>
                                {call._sentiment > 0 ? (
                                  <p className="red" style={{ color: "green" }}>
                                    <i
                                      className="fa fa-plus-circle"
                                      aria-hidden="true"
                                    />
                                  </p>
                                ) : (
                                  <p className="red" style={{ color: "red" }}>
                                    <i
                                      className="fa fa-minus-circle"
                                      aria-hidden="true"
                                    />
                                  </p>
                                )}
                              </td>
                              <td>
                                {call._intent > 0 ? (
                                  <p className="red" style={{ color: "green" }}>
                                    <i
                                      className="fa fa-plus-circle"
                                      aria-hidden="true"
                                    />
                                  </p>
                                ) : (
                                  <p className="red" style={{ color: "red" }}>
                                    <i
                                      className="fa fa-minus-circle"
                                      aria-hidden="true"
                                    />
                                  </p>
                                )}
                              </td>
                            </>
                          )}

                          {/* <td>{Math.abs(Math.trunc(call?._sentiment * 100))}%</td>
                        <td>{Math.abs(Math.trunc(call?._intent * 100))}%</td> */}
                          {call._intent === 0 && (
                            <>
                              {localStorage.getItem("collection_module") ===
                                "true" && (
                                <td
                                  colSpan="4"
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  title={call._processing_status}
                                >
                                  {call._processing_status !== "Failed" && (
                                    <>
                                      <div className="col-md-10">
                                        <ProgressBar
                                          bgcolor="#271078"
                                          progress={call._progress}
                                          // progress={progess}
                                          height={20}
                                        />
                                      </div>
                                      <div className="col-md-2">
                                        <PulseLoader
                                          color="#2056FF"
                                          size="10px"
                                        />
                                      </div>
                                    </>
                                  )}
                                  {call._processing_status === "Failed" && (
                                    <>
                                      <BiError size="20px" color="#FF0800" />{" "}
                                      Call Processing Failed, Need Action
                                      <a
                                        onClick={() =>
                                          removeFailedItem(call._id)
                                        }
                                        className="pull-right"
                                        style={{ color: "red" }}
                                      >
                                        <RiDeleteRow size="20px" /> Remove{" "}
                                      </a>
                                    </>
                                  )}
                                </td>
                              )}

                              {localStorage.getItem("collection_module") !==
                                "true" && (
                                <td
                                  colSpan="2"
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  title={call._processing_status}
                                >
                                  {call._processing_status !== "Failed" && (
                                    <>
                                      <div className="col-md-10">
                                        <ProgressBar
                                          bgcolor="#271078"
                                          progress={call._progress}
                                          // progress={progess}
                                          height={20}
                                        />
                                      </div>
                                      <div className="col-md-2">
                                        <PulseLoader
                                          color="#2056FF"
                                          size="10px"
                                        />
                                      </div>
                                    </>
                                  )}
                                  {call._processing_status === "Failed" && (
                                    <>
                                      <BiError size="20px" color="#FF0800" />{" "}
                                      Call Processing Failed, Need Action
                                      <a
                                        onClick={() =>
                                          removeFailedItem(call._id)
                                        }
                                        className="pull-right"
                                        style={{ color: "red" }}
                                      >
                                        <RiDeleteRow size="20px" /> Remove{" "}
                                      </a>
                                    </>
                                  )}
                                </td>
                              )}
                            </>
                          )}
                        </tr>
                      ) : (
                        <tr
                          key={call._id}
                          onClick={() =>
                            openCallInsight(call._id, call._intent)
                          }
                        >
                          <td>{call?._date}</td>
                          <td>{call?._customer}</td>
                          <td>{call?._agent}</td>
                          <td>
                            {call._language.includes("English")
                              ? "English"
                              : call._language}
                          </td>
                          <td>{call._product_name}</td>
                          <td>{call._call_type}</td>

                          {localStorage.getItem("collection_module") ===
                            "true" && (
                            <>
                              {call._intent !== 0 && (
                                <>
                                  {call._call_type === "Collection" ? (
                                    <>
                                      <td>
                                        <p>{call._agent_collection_view}</p>
                                      </td>
                                      <td>
                                        <p>{call._dataklout_collection_view}</p>
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td>
                                        <p>NA</p>
                                      </td>
                                      <td>
                                        <p>NA</p>
                                      </td>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}

                          {call._intent !== 0 && (
                            <>
                              <td>
                                {call._sentiment > 0 ? (
                                  <p className="red" style={{ color: "green" }}>
                                    <i
                                      className="fa fa-plus-circle"
                                      aria-hidden="true"
                                    />
                                  </p>
                                ) : (
                                  <p className="red" style={{ color: "red" }}>
                                    <i
                                      className="fa fa-minus-circle"
                                      aria-hidden="true"
                                    />
                                  </p>
                                )}
                              </td>
                              <td>
                                {call._intent > 0 ? (
                                  <p className="red" style={{ color: "green" }}>
                                    <i
                                      className="fa fa-plus-circle"
                                      aria-hidden="true"
                                    />
                                  </p>
                                ) : (
                                  <p className="red" style={{ color: "red" }}>
                                    <i
                                      className="fa fa-minus-circle"
                                      aria-hidden="true"
                                    />
                                  </p>
                                )}
                              </td>
                            </>
                          )}

                          {call._intent === 0 && (
                            <>
                              {localStorage.getItem("collection_module") ===
                                "true" && (
                                <td
                                  colSpan="4"
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  title={call._processing_status}
                                >
                                  {call._processing_status !== "Failed" && (
                                    <>
                                      <div className="col-md-10">
                                        <ProgressBar
                                          bgcolor="#271078"
                                          progress={call._progress}
                                          // progress={progess}
                                          height={20}
                                        />
                                      </div>
                                      <div className="col-md-2">
                                        <PulseLoader
                                          color="#2056FF"
                                          size="10px"
                                        />
                                      </div>
                                    </>
                                  )}
                                  {call._processing_status === "Failed" && (
                                    <>
                                      <BiError size="20px" color="#FF0800" />{" "}
                                      Call Processing Failed, Need Action
                                      <a
                                        onClick={() =>
                                          removeFailedItem(call._id)
                                        }
                                        className="pull-right"
                                        style={{ color: "red" }}
                                      >
                                        <RiDeleteRow size="20px" /> Remove{" "}
                                      </a>
                                    </>
                                  )}
                                </td>
                              )}

                              {localStorage.getItem("collection_module") !==
                                "true" && (
                                <td
                                  colSpan="2"
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  title={call._processing_status}
                                >
                                  {call._processing_status !== "Failed" && (
                                    <>
                                      <div className="col-md-10">
                                        <ProgressBar
                                          bgcolor="#271078"
                                          progress={call._progress}
                                          // progress={progess}
                                          height={20}
                                        />
                                      </div>
                                      <div className="col-md-2">
                                        <PulseLoader
                                          color="#2056FF"
                                          size="10px"
                                        />
                                      </div>
                                    </>
                                  )}
                                  {call._processing_status === "Failed" && (
                                    <>
                                      <BiError size="20px" color="#FF0800" />{" "}
                                      Call Processing Failed, Need Action
                                      <a
                                        onClick={() =>
                                          removeFailedItem(call._id)
                                        }
                                        className="pull-right"
                                        style={{ color: "red" }}
                                      >
                                        <RiDeleteRow size="20px" /> Remove{" "}
                                      </a>
                                    </>
                                  )}
                                </td>
                              )}
                            </>
                          )}
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            )}

            <Pagination
              currentPage={currentPage}
              totalPages={totalpage.length}
              onPageChange={handlePageClick}
              totalresult={totalresult}
            />
          </div>
        </div>
      </div>
      <Footer />
      {showCallModal && (
        <div className="modal my-calls-popup show">
          <div className="backdrop"></div>
          <div className="modal-dialog" role="document">
            <div className="my-calls-popup-details">
              <h2>
                <div className="icon-div">
                  <div>
                    <span>
                      <img
                        src="assets/images/user-icon.png"
                        className="img-responsive"
                        alt=""
                      />
                    </span>
                  </div>
                </div>
                {bulkupload ? "Add Bulk Call" : "Add New Call"}
              </h2>
              <div className="text-end mt-6">
                <button
                  className=" px-4 py-2 bulk-upload"
                  style={{ border: "1px solid black" }}
                  onClick={() => {
                    setBulkupload(!bulkupload);
                    setEmptycall(false);
                  }}
                >
                  {bulkupload ? "Single Upload" : "Bulk Upload"}
                </button>
              </div>

              {!bulkupload ? (
                <span>
                  <div className="my-calls-form">
                    {supportingInfo && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-col clearfix">
                            <label>Call Type </label>
                            <br />
                            <select
                              onChange={(e) => setCallType(e.target.value)}
                            >
                              <option value=""></option>
                              {supportingInfo.call_type.map((callType) => (
                                <option value={callType.title}>
                                  {callType.title}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="form-col clearfix">
                            <label>Product </label>
                            <br />
                            <select
                              onChange={(e) => setProduct(e.target.value)}
                            >
                              <option value=""></option>
                              {supportingInfo.product.map((product) => (
                                <option value={product.title}>
                                  {product.title}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="form-col clearfix">
                            <label>Language </label>
                            <br />
                            <select
                              onChange={(e) => setLanguageCode(e.target.value)}
                            >
                              <option value=""></option>
                              {supportingInfo.language.map((languageItem) => (
                                <option value={languageItem.code}>
                                  {languageItem.title}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-col clearfix">
                            <label>Customer </label>
                            <br />
                            <Select
                              options={supportingInfo.customer.map(
                                (customerItem) => ({
                                  value: customerItem.id,
                                  label: [
                                    customerItem.salutation,
                                    customerItem.first_name,
                                    customerItem.middle_name,
                                    customerItem.last_name,
                                  ]
                                    .filter(Boolean)
                                    .join(" "),
                                })
                              )}
                              onChange={(selectedOption) =>
                                setCustomerID(selectedOption.value)
                              }
                              // placeholder="Select a customer"
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,

                                  backgroundColor: "#e6eaed",
                                  height: "44px",
                                  border: "none",
                                  outline: "none",
                                  boxShadow: "none",
                                  "&:focus": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }),
                                container: (baseStyles, state) => ({
                                  ...baseStyles,
                                }),
                                indicatorSeparator: (baseStyles, state) => ({
                                  ...baseStyles,
                                  width: "0px",
                                }),
                                dropdownIndicator: (baseStyles, state) => ({
                                  ...baseStyles,
                                  margin: "0px 10px 15px 10px", // Remove default padding
                                  borderRadius: "100%",
                                  border: "2px solid ",
                                  padding: "0px",
                                }),
                              }}
                              noOptionsMessage={() => null}
                              placeholder=""
                            />
                          </div>

                          <div className="form-col clearfix">
                            <label>Call Recording </label>
                            <br />
                            <input
                              style={{ padding: "7px" }}
                              type="file"
                              accept=".wav"
                              onChange={(e) => setUploadFile(e.target.files[0])}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {supportingInfoPending && (
                      <div className="empty-call">Loading...</div>
                    )}

                    <div className="row">
                      <div className="col-md-12">
                        <p className="errorColor">{supportingInfoError}</p>
                        {uploadError && (
                          <p className="errorColor">{uploadError}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="border"></div>
                  <div className="popup-footer">
                    {!uploadPending && (
                      <>
                        <button
                          className="btn"
                          type="button"
                          onClick={toggolCallModal}
                        >
                          {" "}
                          Cancel{" "}
                        </button>
                        <button
                          className="btn Save"
                          type="button"
                          onClick={uploadCallRecording}
                        >
                          {" "}
                          Upload{" "}
                        </button>
                      </>
                    )}

                    {uploadPending && (
                      <ProgressBar
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Tooltip on top"
                        bgcolor="#271078"
                        progress={callUploadProgress}
                        height={20}
                      />
                    )}
                  </div>
                </span>
              ) : (
                <span>
                  <div className="my-calls-form">
                    {supportingInfo && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-col clearfix">
                            <label>Call Type </label>
                            <br />
                            <select
                              onChange={(e) =>
                                handelBulkChange("call_type", e.target.value)
                              }
                              name="call_type"
                              id="call_type"
                              value={bulkdata.call_type}
                            >
                              <option value=""></option>
                              {supportingInfo.call_type.map((callType) => (
                                <option
                                  key={callType.title}
                                  value={callType.title}
                                >
                                  {callType.title}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="form-col clearfix">
                            <label>Product </label>
                            <br />
                            <select
                              onChange={(e) =>
                                handelBulkChange("product", e.target.value)
                              }
                              name="product"
                              id="product"
                              value={bulkdata.product}
                            >
                              <option value=""></option>
                              {supportingInfo.product.map((product) => (
                                <option
                                  key={product.title}
                                  value={product.title}
                                >
                                  {product.title}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="form-col clearfix">
                            <label>Language </label>
                            <br />
                            <select
                              onChange={(e) =>
                                handelBulkChange(
                                  "language_code",
                                  e.target.value
                                )
                              }
                              name="language_code"
                              id="language_code"
                              value={bulkdata.language_code}
                            >
                              <option value=""></option>
                              {supportingInfo.language.map((languageItem) => (
                                <option
                                  key={languageItem.code}
                                  value={languageItem.code}
                                >
                                  {languageItem.title}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-col clearfix">
                            <label>Customer</label>
                            <br />

                            <Select
                              options={supportingInfo.customer.map(
                                (customerItem) => ({
                                  value: customerItem.id,
                                  label: [
                                    customerItem.salutation,
                                    customerItem.first_name,
                                    customerItem.middle_name,
                                    customerItem.last_name,
                                  ]
                                    .filter(Boolean)
                                    .join(" "),
                                })
                              )}
                              onChange={(selectedOption) =>
                                handelBulkChange(
                                  "customer_id",
                                  selectedOption.value
                                )
                              }
                              placeholder=""
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,

                                  backgroundColor: "#e6eaed",
                                  height: "44px",
                                  border: "none",
                                  outline: "none",
                                  boxShadow: "none",
                                  "&:focus": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }),
                                container: (baseStyles, state) => ({
                                  ...baseStyles,
                                }),
                                indicatorSeparator: (baseStyles, state) => ({
                                  ...baseStyles,
                                  width: "0px",
                                }),
                                dropdownIndicator: (baseStyles, state) => ({
                                  ...baseStyles,
                                  margin: "0px 10px 15px 10px",
                                  borderRadius: "100%",
                                  border: "2px solid ",
                                  padding: "0px",
                                }),
                              }}
                              value={
                                supportingInfo.customer.find(
                                  (item) => item.id === bulkdata.customer_id
                                )
                                  ? {
                                      value: bulkdata.customer_id,
                                      label: [
                                        supportingInfo.customer.find(
                                          (item) =>
                                            item.id === bulkdata.customer_id
                                        ).salutation,
                                        supportingInfo.customer.find(
                                          (item) =>
                                            item.id === bulkdata.customer_id
                                        ).first_name,
                                        supportingInfo.customer.find(
                                          (item) =>
                                            item.id === bulkdata.customer_id
                                        ).middle_name,
                                        supportingInfo.customer.find(
                                          (item) =>
                                            item.id === bulkdata.customer_id
                                        ).last_name,
                                      ]
                                        .filter(Boolean)
                                        .join(" "),
                                    }
                                  : null
                              }
                            />
                          </div>

                          <div className="form-col clearfix">
                            <label>Call Recording </label>
                            <br />
                            <input
                              key={fileInputKey}
                              style={{ padding: "7px" }}
                              type="file"
                              accept=".wav"
                              onChange={(e) =>
                                handelBulkChange("files", e.target.files[0])
                              }
                              // name="file_key"
                              // id="file_key"
                              // value={bulkdata.file_key}
                            />
                          </div>

                          <div className=" flex justify-end">
                            Total calls Added: {addBulkCall.length}
                          </div>
                          <div className=" h-[100px] overflow-scroll role">
                            {addBulkCall.length >= 1 ? (
                              <div className=" flex">
                                <ul className=" list-disc ml-8">
                                  {addBulkCall?.map((e, i) => (
                                    <li className=" flex justify-between  items-center">
                                      <span>{e?.files?.name} </span>
                                      <span
                                        className=" ml-[20px] mt-[4px] cursor-pointer"
                                        onClick={() => handleDeleteCall(i)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-x"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                        </svg>
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ) : (
                              <ul className=" list-disc ml-8">
                                <li>No Calls Added to upload list</li>
                              </ul>
                            )}
                            {emptycall ? (
                              <div className=" text-red-800">
                                Enter the Call details
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {supportingInfoPending && (
                      <div className="empty-call">Loading...</div>
                    )}
                    {durationerror ? (
                      <div className=" text-red-800">
                        The duration of call is more then 200 hours
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="row">
                      <div className="col-md-12">
                        <p className="errorColor">{supportingInfoError}</p>
                        {uploadError && (
                          <p className="errorColor">{uploadError}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="border"></div>
                  <div className="popup-footer">
                    {!uploadPending && (
                      <>
                        <button
                          className="btn"
                          type="button"
                          onClick={() => {
                            toggolCallModal();
                            setEmptycall(false);
                            setDurationerror(false);
                          }}
                        >
                          {" "}
                          Cancel{" "}
                        </button>
                        <button className="btn" type="button" onClick={addCall}>
                          {" "}
                          Add Call
                        </button>
                        <button
                          className="btn Save"
                          type="button"
                          onClick={handleUpload}
                        >
                          {" "}
                          Upload{" "}
                        </button>
                      </>
                    )}

                    {uploadPending && (
                      <ProgressBar
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Tooltip on top"
                        bgcolor="#271078"
                        progress={callUploadProgress}
                        height={20}
                      />
                    )}
                  </div>
                </span>
              )}
            </div>
          </div>
        </div>
      )}
      {callUploadSucess && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-gray-900 bg-opacity-50"></div>
          <div className="bg-white rounded-lg p-8 z-10 w-[500px] h-[250px] text-center ">
            <div className=" flex justify-center mt-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                class="bi bi-hourglass-split spin"
                viewBox="0 0 16 16"
              >
                <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
              </svg>
            </div>
            <div className=" mt-8">
              <h3>Thank you </h3>
              <p className=" text-red-500 text-[15px]">
                You will receive an email, once the calls are processed
              </p>
            </div>

            <button
              onClick={() => setCallUploadSucess(false)}
              className=" mt-10 px-8 py-4 bg-blue-900 text-white rounded hover:bg-orange-600 text-2xl"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CallList;
